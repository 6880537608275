(function($) {
    var Sage = {
        'common': {
            init: function() {
                /**
                 * declaracion variables globales
                 */
                var anchoventana = $(document).width();
                var altoventana = $(document).height();
                var md = 768;
                /**
                 * init plugins y listeners comunes de proyectos
                 */
                initPlugins();
                initListeners();
            },
            finalize: function() {
                var swiper = new Swiper('.swiper-container', {
                    slidesPerView: 3,
                    spaceBetween: 60,
                    nextButton: '.arrow-left',
                    prevButton: '.arrow-right',
                    loop: false,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    breakpoints: {
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 15
                        },
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 5
                        }
                    }
                });
                var swiper2 = new Swiper('.swiper-container-testimonios', {
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    spaceBetween: 30,
                    freeMode: true,
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination2',
                        clickable: true,
                    },
                });
                $('.nav-item a').on('click', function() {
                    console.log('event click');
                    $('.navbar-collapse').collapse('toggle');
                });
            }
        },
    };
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';
            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });
            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.