/**
 * init plugins
 */
initPlugins=function(){
  //algunos plugins interesa solo inicializarlos en mobile
  var anchoventana=$(document).width();
  var altoventana=$(document).height();
  var md=768;
  if (anchoventana>=md){
      /*animaciones WOW*/
        new WOW().init();

      /* scroll parallax */
        //var s = skrollr.init();          
  }




};